import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import joinClasses from '/src/functions/joinClasses'
import purpleIcon from '/src/dummy-data/images/purpleIcon'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))

const ImageBulletList = ({ columns, itemsRepeater }) => {
    const classes = [
        'c-image-bullet-list',
        ...(columns === '2' ? ['c-image-bullet-list--two-columns'] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <ul className={concatenatedClasses}>
            {itemsRepeater.map((node, index) => {
                const itemClasses = [
                    'c-image-bullet-list__item',
                    ...(node.description
                        ? ['c-image-bullet-list__item--has-description']
                        : [])
                ]

                const concatenatedItemClasses = joinClasses(itemClasses)

                return (
                    <li key={index} className={concatenatedItemClasses}>
                        {node.image && (
                            <Image
                                data={node.image}
                                className={'c-image-bullet-list__item-image'}
                            />
                        )}

                        <div>
                            {node.title && (
                                <h3 className="c-image-bullet-list__item-title">
                                    {parse(node.title)}
                                </h3>
                            )}

                            {node.description && (
                                <div className="c-image-bullet-list__item-description">
                                    {parse(node.description)}
                                </div>
                            )}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

ImageBulletList.propTypes = {
    columns: PropTypes.oneOf(['1', '2']),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object,
            title: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired
}

ImageBulletList.defaultProps = {
    columns: '1',
    itemsRepeater: [
        {
            image: purpleIcon,
            title: 'Item title'
        },
        {
            image: purpleIcon,
            title: 'Item title'
        },
        {
            image: purpleIcon,
            title: 'Item title'
        }
    ]
}

export default ImageBulletList
